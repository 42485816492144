import {useTranslation} from 'next-i18next'
import {ClassNameMap} from '@material-ui/styles'
import AppTypographyLink from 'src/components/elements/typography/AppTypographyLink'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_FR} from 'src/constants/locale'
import AppTypography from 'src/components/elements/typography/AppTypography'

interface Props {
  image: string
  label?: string
  description?: string
  label_en?: string
  label_fr?: string
  style?: React.CSSProperties
  className?: string
  classes?: ClassNameMap
  children?: React.ReactNode
  description_en?: string
  description_fr?: string
  link?: string
}

export default function DiscoverCard({
  image,
  label,
  description,
  label_en,
  label_fr,
  classes,
  children,
  description_en,
  description_fr,
  link,
  ...otherProps
}: Props) {
  const {t} = useTranslation('common')

  const currentLocale = useCurrentLocale()

  const activeLabel = label
    ? label
    : currentLocale === LOCALE_FR
    ? label_fr
    : label_en
  const activeDescription = description
    ? description
    : currentLocale === LOCALE_FR
    ? description_fr
    : description_en

  return (
    <div className={classes?.discoverCardRoot}>
      <img loading="lazy" src={image} alt={label} {...otherProps} />
      <div className={classes?.textWrapper}>
        <AppTypography
          variant="headingSlim"
          className={classes?.discoverCardRootItemTitle}
        >
          {activeLabel}
        </AppTypography>
        <AppTypography
          variant="body"
          className={classes?.discoverCardRootSubtitleTitle}
        >
          {activeDescription}
        </AppTypography>
        {children}
        {link ? (
          <AppTypographyLink
            href={link}
            className="flex flex-row justify-start items-center mt-2 text-secondary-main"
          >
            {t('learn_more')}
            <AppIcon name="arrow-right-s-line-1" />
          </AppTypographyLink>
        ) : (
          <AppTypography
            variant="displayXSmall"
            className="flex flex-row justify-start items-center mt-2 text-secondary-main"
          >
            {t('learn_more')}
            <AppIcon name="arrow-right-s-line-1" />
          </AppTypography>
        )}
      </div>
    </div>
  )
}
