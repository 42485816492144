import {makeStyles} from '@material-ui/core/styles'
import {interFontFamily} from 'src/components/materialTheme/typography'
import {globalZIndex} from 'src/utils/styles'

export const useStyles = makeStyles(
  ({spacing, palette, breakpoints}) => ({
    descriptionTitle: {
      marginTop: spacing(0.5),
    },
    descriptionSubDetails: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(1.25),
      gap: spacing(1.5),
      paddingTop: spacing(1.5),
    },
    subHeading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    link: {
      marginTop: spacing(0.5),
      color: palette.primary.main,
      fontFamily: interFontFamily,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
    linkImage: {
      position: 'relative',
      display: 'block',
      aspectRatio: '1900/1100',
      width: '100%',
      maxWidth: '100%',
      minHeight: 200,

      [breakpoints.up(1128)]: {
        minHeight: 230,
      },
    },
    previewImage: {
      position: 'absolute',
      inset: 0,
      zIndex: globalZIndex.aboveBase,
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      borderRadius: spacing(1.25),
      aspectRatio: '1900/1100',
      maxWidth: '100%',
      minHeight: 200,
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',

      [breakpoints.up(1128)]: {
        minHeight: 230,
      },
    },
    priceContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.24)',
      gap: 2,
      backdropFilter: 'blur(32px)',
      borderRadius: spacing(0, 0.75, 0, 1.5),
      padding: spacing(1, 2),
      display: 'flex',
      flexDirection: 'column',
      zIndex: globalZIndex.aboveBase,
    },
    priceTitleCaption: {
      fontWeight: 500,
      fontSize: '10px !important',
      color: palette.common.white,
    },
    priceTitle: {
      fontWeight: 500,
      fontSize: '14px !important',
      color: palette.common.white,
    },
    priceSubtitle: {
      fontWeight: 400,
      fontSize: '12px !important',
      color: palette.common.white,
    },
    priceUnitContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(1 / 2),
    },
  }),
  {index: 1},
)
