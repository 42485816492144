import Container from 'src/components/helpers/Container'
import {useCurrentLocale} from 'src/hooks/locale'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {LOCALE_FR} from 'src/constants/locale'
import AppIcon from 'src/components/elements/icons/AppIcon'

type HomeInfoBoxType = {
  icon: string
  iconWidth: number
  titleEn: string
  descriptionEn: string
  titleFr: string
  descriptionFr: string
}

const infoBoxData: HomeInfoBoxType[] = [
  {
    icon: 'mc-home',
    iconWidth: 32,
    titleEn: 'Exclusive collection',
    descriptionEn:
      'Luxury chalets carefully selected and inspected to offer you the best quality in the industry. We are the guardians of your vacation.',
    titleFr: 'Collection exclusive',
    descriptionFr:
      "Des chalets de luxe soigneusement sélectionnés et inspectés pour vous offrir la meilleure qualité de l'industrie. Nous sommes les gardiens de vos vacances.",
  },
  {
    icon: 'mc-payment',
    iconWidth: 32,
    titleEn: '100% Quebec Company',
    descriptionEn:
      'Support a local business that has been a leader in the industry for 10 years. We hire locally and are proud to work with Quebec companies.',
    titleFr: '100% Québécois',
    descriptionFr:
      "Encouragez une entreprise locale qui est un leader dans l'industrie depuis 10 ans. Nous embauchons local et sommes fiers de travailler avec des entreprises du Québec.",
  },
  {
    icon: 'mc-logo-small-outline',
    iconWidth: 36,
    titleEn: 'Butler Service',
    descriptionEn:
      'Upgrade your chalets stays with tailor-made services. Catering, massage therapy, private driver, private chef, etc.',
    titleFr: 'Service de Majordome',
    descriptionFr:
      "Surclassez vos séjours à l'aide de prestations de services sur mesure. Traiteur, massothérapie, chauffeur privé, chef privé, etc.",
  },
]

export default function InfoBoxes() {
  return (
    <Container maxWidth="xl">
      <div className="mt-[180px] md:mt-16 sm:px-24 md:px-12 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-24">
          {infoBoxData.map((info, idx) => (
            <div key={idx}>
              <InfoBox {...info} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

function InfoBox(props: HomeInfoBoxType) {
  const currentLocale = useCurrentLocale()

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="h-10">
        <AppIcon
          className="object-contain object-center h-auto text-gray-900"
          style={{
            width: `${props.iconWidth}px`,
            minWidth: `${props.iconWidth}px`,
          }}
          name={props.icon}
        />
      </div>
      <AppTypography
        variant="displayMedium"
        neutralColor={900}
        className="pt-3 text-center"
        component="h2"
      >
        {currentLocale === LOCALE_FR ? props.titleFr : props.titleEn}
      </AppTypography>
      <AppTypography
        className="text-text-secondary text-center pt-1"
        component="p"
        variant="body"
      >
        {currentLocale === LOCALE_FR
          ? props.descriptionFr
          : props.descriptionEn}
      </AppTypography>
    </div>
  )
}
