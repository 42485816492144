import {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Title from 'src/components/modules/hero/Title'
import {useStyles} from './styles'
import dynamic from 'next/dynamic'
import Container from 'src/components/helpers/Container'
import SubTitle from 'src/components/modules/hero/SubTitle'
import {useScreenSizeUp} from 'src/hooks/screenSize'

const SearchBox = dynamic(() => import('./SearchBox'), {
  ssr: false,
})

const MobileSearch = dynamic(
  () =>
    import('src/components/modules/home/homeHero/mobileSearch/MobileSearch'),
  {
    ssr: false,
  },
)

export default function HomeHero() {
  const classes = useStyles()
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const {t} = useTranslation('hero')
  const isUpMd = useScreenSizeUp('md')

  useEffect(() => {
    setShowSearch(true)
  }, [])

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.nextImageContainer}>
          <img
            src="/images/homepage-mobile.webp?ver=1.0.2"
            className={classes.nextImageMobile}
            alt="MonsieurChalets hero"
            //@ts-ignore
            fetchpriority="high"
          />
          <img
            src="/images/homepage-tablet.webp?ver=1.0.2"
            className={classes.nextImageTablet}
            alt="MonsieurChalets hero"
            //@ts-ignore
            fetchpriority="high"
          />
          <img
            src="/images/homepage-desktop.webp?ver=1.0.2"
            className={classes.nextImageDesktop}
            alt="MonsieurChalets hero"
            //@ts-ignore
            fetchpriority="high"
          />
        </div>
        <Container disableGutters className={classes.container} maxWidth="xl">
          <div className="flex flex-col justify-center items-center h-full">
            <div className={classes.contentContainer}>
              <Title title={t('title')} />
              <SubTitle subTitle={t('sub_title')} />
              {showSearch && (
                <>
                  {isUpMd ? (
                    <div className={classes.desktopSearch}>
                      <SearchBox />
                    </div>
                  ) : (
                    <div className={classes.mobileSearch}>
                      <MobileSearch elivated />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  )
}
