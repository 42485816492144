import {useTranslation} from 'next-i18next'
import dynamic from 'next/dynamic'
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Hidden from 'src/components/helpers/Hidden'

import {useCurrentLocale} from 'src/hooks/locale'
import {useSmallerSize, useXLargeSize} from 'src/hooks/screenSize'
import {PropertyCardType} from 'src/types/property'
import {
  getPropertyName,
  usePropertyAddress,
  useReviewInfo,
} from 'src/hooks/property'
import {APP_SLUG, SLUG_MC} from 'src/constants/slug'
import {formattedPhoto, getBlurDataUrl} from 'src/utils/picture'
import {getPropertyPhotoAltText, getUniquePropertyUrl} from 'src/utils/property'
import {useStyles} from './styles'
import {useEffect, useRef, useState} from 'react'
import debounce from 'lodash-es/debounce'
import {getCurrencySymbol, isElementInViewport} from 'src/utils/other'
import {FavoriteButton} from 'src/components/modules/imageSlider/Buttons'
import Visible from 'src/components/helpers/Visible'
import {useIsTouchDevice} from 'src/hooks/dom'
import PropertyPromosTags from 'src/components/modules/property/promo/PropertyPromosTags'

const blurDataURL = getBlurDataUrl(400, 200)

const ImageSlider = dynamic(
  () => import('src/components/modules/imageSlider/ImageSlider'),
  {
    ssr: false,
    loading: () => <img src={blurDataURL} alt="loading" />,
  },
)

export default function PropertyCard(props: {
  data: PropertyCardType
  preloadImageSlider?: boolean
  isFavorite?: boolean
}) {
  const {data, isFavorite, preloadImageSlider = false} = props

  const {t} = useTranslation(['common', 'property'])
  const classes = useStyles()
  const [showSwiperInstance, setShowSwiperInstance] =
    useState(preloadImageSlider)
  const [showFirstImage, setShowFirstImage] = useState(!preloadImageSlider)
  const rootNodeRef = useRef<HTMLElement | null>(null)
  const currencySymbol = getCurrencySymbol(data.currency)
  const currentLocale = useCurrentLocale()
  const propertyName = getPropertyName(data, currentLocale)
  const propertyAddress = usePropertyAddress(data, false)
  const imageMaxWidth = useImageMaxWidth()
  const reviewInfo = useReviewInfo(data.review_count)
  const isTouchDevice = useIsTouchDevice()

  const url = getUniquePropertyUrl(currentLocale, data)

  const bathroom = Math.trunc(data?.number_of_rooms_bath)

  const onSwiperReady = () => {
    if (!showFirstImage) {
      return //skip if already false
    }

    //wait 300 just to make sure
    setTimeout(() => {
      setShowFirstImage(false)
    }, 300)
  }
  const onMouseEnter = () => {
    if (showSwiperInstance) {
      return //skip if already true
    }

    setShowSwiperInstance(true)
  }

  //we need that for mobile!
  const onScroll = debounce(() => {
    if (isElementInViewport(rootNodeRef.current)) {
      setShowSwiperInstance(true)
      window.removeEventListener('scroll', onScroll)
    }
  }, 200)

  useEffect(() => {
    if (showSwiperInstance) return //dont init listener
    if (!isTouchDevice) return //dont add scroll event on device without touch screen

    window.addEventListener('scroll', onScroll, {passive: true})

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTouchDevice])

  const firstImage =
    data.photos.length > 0
      ? formattedPhoto(data.photos[0].image, imageMaxWidth ?? 400)
      : null

  let altText =
    data.photos.length > 0
      ? getPropertyPhotoAltText(data.photos[0], currentLocale)
      : ''

  const lowestRate = data.calculated_lowest_rate || data.base_rate

  return (
    <a
      className="block relative cursor-pointer"
      href={url}
      onMouseEnter={onMouseEnter}
    >
      <div className={classes.linkImage}>
        <Visible when={showSwiperInstance}>
          <ImageSlider
            photos={data.photos}
            propertyId={data.id}
            photoCount={data.photo_count ?? data.photos.length}
            photoWidth={imageMaxWidth}
            onSwiperReady={onSwiperReady}
          />
        </Visible>
        <Visible when={Boolean(showFirstImage && firstImage)}>
          <img
            className={classes.previewImage}
            ref={(node) => (rootNodeRef.current = node)}
            loading="lazy"
            src={firstImage?.url}
            alt={altText}
          />
        </Visible>
        <FavoriteButton visible isFavorite={isFavorite} propertyId={data.id} />
        <div className="absolute left-2 top-2 z-above-base">
          <PropertyPromosTags position="search" white property={data} />
        </div>
        <div className={classes.priceContainer}>
          <AppTypography
            variant="caption"
            className={classes.priceTitleCaption}
          >
            {t('From')}
          </AppTypography>
          <div className={classes.priceUnitContainer}>
            <AppTypography variant="caption" className={classes.priceTitle}>
              {currencySymbol}
              {lowestRate}&nbsp;/
            </AppTypography>

            <AppTypography variant="caption" className={classes.priceSubtitle}>
              {t('night')}
            </AppTypography>
          </div>
        </div>
      </div>
      <div>
        <AppTypography
          variant="caption"
          neutralColor={500}
          className={classes.descriptionSubDetails}
        >
          {`${data?.maximum_guests} ${t('guests_other', {
            ns: 'property',
          }).toLowerCase()}`}
          <span className="h-2 bg-gray-200 w-px" />
          {`${data.number_of_rooms} ${t(
            data.number_of_rooms > 1 ? 'bedrooms_other' : 'bedroom_other',
          ).toLowerCase()}`}
          <span className="h-2 bg-gray-200 w-px" />
          {`${bathroom} ${t(
            bathroom > 1 ? 'bathrooms' : 'bathroom',
          ).toLowerCase()}`}
        </AppTypography>

        <span className={classes.link}>{propertyName}</span>

        <div className={classes.subHeading}>
          <div className="mt-1">
            <AppTypography className="text-text-secondary" variant="caption">
              {propertyAddress}
            </AppTypography>
          </div>
          <Hidden when={true}>
            <div className="flex flex-row justify-center items-center">
              <StarRoundedIcon style={{color: '#19647E', marginBottom: 3}} />
              <AppTypography variant="caption" neutralColor={800}>
                {data.average_rating?.toFixed(2)}
              </AppTypography>
              <AppTypography variant="caption" neutralColor={500}>
                {reviewInfo}
              </AppTypography>
            </div>
          </Hidden>
        </div>
      </div>
    </a>
  )
}

function useImageMaxWidth() {
  const isSmallerSize = useSmallerSize()
  const isLargerSize = useXLargeSize()

  if (isSmallerSize) {
    return 400
  }

  // higher res for larger size
  if (isLargerSize) {
    return APP_SLUG === SLUG_MC ? 512 : 512
  }

  return APP_SLUG === SLUG_MC ? 512 : 400
}
