import classes from './styles.module.scss'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import CustomIconSvg from 'src/components/elements/icons/svgs/CustomIconSvg'
import {dayjs} from 'src/utils/date'
import {useTranslation} from 'next-i18next'
import {useAppContext} from 'src/context/AppProvider'
import {publicAddDataCollectionApi} from 'src/services/api/dataCollection'

const nowInMtl = dayjs().tz('America/Montreal') //make sure to use same timezone as phone center
export default function TiredOfSearchingCta(props: {
  className?: string
  size?: 'small' | 'large'
  propertyId?: number
  title?: string
  description?: string
  onContactUs?: () => void
}) {
  const {
    title,
    description,
    onContactUs,
    size,
    propertyId,
    className = '',
  } = props
  const {t} = useTranslation('common')
  const canPhoneCall =
    nowInMtl.hour() >= 9 &&
    nowInMtl.hour() <= 15 &&
    [1, 2, 3, 4, 5].includes(nowInMtl.day()) //9am to 3pm and monday to friday

  const {setModal} = useAppContext()
  const handlePhoneCall = () => {
    publicAddDataCollectionApi('tired_of_searching_cta', {
      btn_clicked: 'phone_call',
      property_id: propertyId,
    })
    window.open('tel:+18446242538')
  }

  const toggleOpenBookingRequest = () => {
    publicAddDataCollectionApi('tired_of_searching_cta', {
      btn_clicked: 'contact_us',
      property_id: propertyId,
    })
    if (onContactUs) {
      onContactUs()
      return
    }
    setModal('contact-us')
  }

  return (
    <div
      className={`bg-accent-500 rounded-xl text-black px-2 py-8 flex flex-col justify-center items-center ${className}`}
    >
      <AppTypography
        variant="displaySmall"
        neutralColor={800}
        component="h3"
        style={{lineHeight: '1.5'}}
        className="text-center uppercase font-bold tracking-wide"
      >
        <span
          className={size === 'small' ? classes.titleSmall : classes.titleLarge}
          dangerouslySetInnerHTML={{
            __html: title ? title : t('tired_of_searching'),
          }}
        />
      </AppTypography>
      <AppTypography variant="body" className="text-center px-2 sm:w-3/4 pt-3">
        {description ? description : t('tired_of_searching_description')}
      </AppTypography>

      <div className="mt-6 flex flex-col justify-center items-center gap-4 md:gap-2">
        {canPhoneCall && (
          <AppRoundedButton
            variant="outlined"
            color="primary"
            className="w-auto"
            onClick={handlePhoneCall}
            startIcon={
              <CustomIconSvg
                icon="phone"
                className="w-4 min-w-4 h-4 block text-black"
              />
            }
          >
            <span className="block">1-844-624-2538</span>
          </AppRoundedButton>
        )}
        <AppRoundedButton
          variant="contained"
          color="primary"
          className="w-auto "
          onClick={toggleOpenBookingRequest}
          startIcon={
            <CustomIconSvg
              icon="message-text"
              className="w-4 min-w-4 h-4 block text-white"
            />
          }
        >
          {t('contact_us')}
        </AppRoundedButton>
      </div>
    </div>
  )
}
