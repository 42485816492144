import {makeStyles} from '@material-ui/core/styles'
export const useStyles = makeStyles(
  ({palette, breakpoints, spacing}) => ({
    background: {
      borderRadius: spacing(2),
      maxWidth: '100%',
      margin: 'auto',
    },
    container: {
      padding: spacing(10, 0),
      [breakpoints.down(375)]: {
        padding: spacing(6, 0),
      },

      '& button': {
        marginTop: spacing(-4), //recenter with photos slider
      },
    },
    image: {
      height: '250px',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: spacing(1.25),
      objectFit: 'cover',
      filter: 'saturate(1.2) brightness(1.03) contrast(1.03)',
      [breakpoints.down(900)]: {
        borderRadius: spacing(2),
        height: '155px',
        width: '100%',
      },
    },
    slider: {
      [breakpoints.down(900)]: {
        marginTop: spacing(-7),
      },
    },
    sliderWrap: {
      [breakpoints.down(900)]: {
        paddingBottom: '0',
      },
    },
    subTitle: {
      margin: spacing(1, 0, 9, 0),
      [breakpoints.down(900)]: {
        textAlign: 'center',
      },
    },
    title: {
      marginBottom: spacing(4),

      [breakpoints.down(900)]: {
        textAlign: 'center',
      },
    },
    discoverCardRoot: {
      aspectRatio: '1900/1100',
      cursor: 'pointer',
    },
    discoverCardRootItemTitle: {
      color: palette.text.primary,
      paddingTop: spacing(2.5),
      paddingBottom: spacing(1),
      [breakpoints.down(900)]: {
        padding: spacing(1.25, 0),
      },
    },
    discoverCardRootSubtitleTitle: {
      color: palette.text.secondary,
      fontSize: '14px',
      letterSpacing: '-0.02em',
      fontWeight: 500,
      lineHeight: '24px',
    },
  }),
  {index: 2},
)
