import Head from 'next/head'
import {useRouter} from 'next/router'
import parse from 'html-react-parser'
import {BASE_URL, HOME_PAGE} from 'src/constants/route'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import {useCurrentLocale} from 'src/hooks/locale'
import {PageType} from 'src/types/page'
import {useBreadcrumbsPageSchema} from 'src/components/modules/page/PageBreadcrumbs'

type HrefLang = {
  href: string
  lang: string
}

export default function HeadContent(props: {
  description: string
  title: string
  keywords: string
  imageUrl: string
  headHtml?: string
  schema?: string
  noindex?: boolean
  hrefLangs?: HrefLang[]
  noHrefLangs?: boolean
  page?: PageType
}) {
  const {
    description,
    title,
    keywords,
    schema = null,
    headHtml = null,
    noindex = false,
    imageUrl = '',
    hrefLangs,
    noHrefLangs,
    page,
  } = props

  const currentLocale = useCurrentLocale()
  const router = useRouter()

  const url =
    (currentLocale !== DEFAULT_LOCALE ? `/${currentLocale}` : '') +
    (router.asPath === HOME_PAGE ? '' : router.asPath)

  const currentPageUrl = `${BASE_URL}${router.asPath}`
  const breadcrumbsPageSchema = useBreadcrumbsPageSchema({
    page,
    currentPageUrl,
  })

  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {keywords && <meta name="keywords" content={keywords} />}
      {imageUrl && (
        <>
          <meta name="image" content={imageUrl} />
          <meta property="og:image" content={imageUrl} />
          <meta name="twitter:image" content={imageUrl} />
        </>
      )}
      <meta name="url" content={BASE_URL + url} />
      {/* Facebook sharing (Open Graph Markup)  */}
      <meta property="og:url" content={BASE_URL + url} />
      <meta
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="monsieurchalets.com" />
      {/* Meta tags for twitter link preview  */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={BASE_URL + url} />
      {/* Lighthouse Details  */}
      {/* <link rel="manifest" href="/manifest.json" /> */}
      {/* fonts  */}
      {noindex && <meta name="robots" content="noindex" />}
      {headHtml && <>{parse(headHtml)}</>}
      {schema && (
        <script
          async
          defer
          type="application/ld+json"
          className="reviews-schema"
          data-ref="26e8fd59dd9ca7639e92d4d0affb80577eea6902"
          dangerouslySetInnerHTML={{__html: schema}}
        />
      )}
      <HrefLangs hrefLangs={hrefLangs} noHrefLang={noHrefLangs} url={url} />
      {breadcrumbsPageSchema && (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(breadcrumbsPageSchema),
            }}
          />
        </>
      )}

      {page && (
        <link rel="alternate" hrefLang={page.locale} href={currentPageUrl} />
      )}

      {/* Add other connected page with different lang */}
      {page &&
        page.other_lang_pages.length > 0 &&
        page.other_lang_pages.map((otherPage) => {
          return (
            <link
              key={otherPage.url}
              rel="alternate"
              hrefLang={otherPage.locale}
              href={otherPage.url}
            />
          )
        })}
    </Head>
  )
}

function HrefLangs(props: {
  hrefLangs?: HrefLang[]
  noHrefLang?: boolean
  url: string
}) {
  const {hrefLangs, noHrefLang, url} = props

  if (noHrefLang) {
    return null
  }

  if (hrefLangs) {
    return (
      <>
        {hrefLangs.map((hrefLang: HrefLang, index: number) => {
          return (
            <link
              key={index}
              rel="alternate"
              hrefLang={hrefLang.lang}
              href={hrefLang.href}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <link rel="alternate" hrefLang="en" href={`${BASE_URL}/en${url}`} />
      <link rel="alternate" hrefLang="fr" href={`${BASE_URL}${url}`} />
    </>
  )
}
